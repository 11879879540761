import { default as indexIwqOVo6u6GMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/index.vue?macro=true";
import { default as indexOr2M8TYiqJMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accessesC7j7lPxvOkMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storeszhN7Yuo8rvMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsZtsKjmC9ZJMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93CHrZR2gWSmMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id].vue?macro=true";
import { default as indexFKXzbXprMgMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/index.vue?macro=true";
import { default as _91id_93VTouUh2e7uMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/users/[id].vue?macro=true";
import { default as indexAPWw0wgztgMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/users/index.vue?macro=true";
export default [
  {
    name: indexIwqOVo6u6GMeta?.name ?? "index",
    path: indexIwqOVo6u6GMeta?.path ?? "/",
    meta: indexIwqOVo6u6GMeta || {},
    alias: indexIwqOVo6u6GMeta?.alias || [],
    redirect: indexIwqOVo6u6GMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CHrZR2gWSmMeta?.name ?? undefined,
    path: _91id_93CHrZR2gWSmMeta?.path ?? "/merchants/:id()",
    meta: _91id_93CHrZR2gWSmMeta || {},
    alias: _91id_93CHrZR2gWSmMeta?.alias || [],
    redirect: _91id_93CHrZR2gWSmMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexOr2M8TYiqJMeta?.name ?? "merchants-id",
    path: indexOr2M8TYiqJMeta?.path ?? "",
    meta: indexOr2M8TYiqJMeta || {},
    alias: indexOr2M8TYiqJMeta?.alias || [],
    redirect: indexOr2M8TYiqJMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: merchant_45accessesC7j7lPxvOkMeta?.name ?? "merchants-id-merchant-accesses",
    path: merchant_45accessesC7j7lPxvOkMeta?.path ?? "merchant-accesses",
    meta: merchant_45accessesC7j7lPxvOkMeta || {},
    alias: merchant_45accessesC7j7lPxvOkMeta?.alias || [],
    redirect: merchant_45accessesC7j7lPxvOkMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/merchant-accesses.vue").then(m => m.default || m)
  },
  {
    name: storeszhN7Yuo8rvMeta?.name ?? "merchants-id-stores",
    path: storeszhN7Yuo8rvMeta?.path ?? "stores",
    meta: storeszhN7Yuo8rvMeta || {},
    alias: storeszhN7Yuo8rvMeta?.alias || [],
    redirect: storeszhN7Yuo8rvMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/stores.vue").then(m => m.default || m)
  },
  {
    name: terminalsZtsKjmC9ZJMeta?.name ?? "merchants-id-terminals",
    path: terminalsZtsKjmC9ZJMeta?.path ?? "terminals",
    meta: terminalsZtsKjmC9ZJMeta || {},
    alias: terminalsZtsKjmC9ZJMeta?.alias || [],
    redirect: terminalsZtsKjmC9ZJMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/[id]/terminals.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFKXzbXprMgMeta?.name ?? "merchants",
    path: indexFKXzbXprMgMeta?.path ?? "/merchants",
    meta: indexFKXzbXprMgMeta || {},
    alias: indexFKXzbXprMgMeta?.alias || [],
    redirect: indexFKXzbXprMgMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VTouUh2e7uMeta?.name ?? "users-id",
    path: _91id_93VTouUh2e7uMeta?.path ?? "/users/:id()",
    meta: _91id_93VTouUh2e7uMeta || {},
    alias: _91id_93VTouUh2e7uMeta?.alias || [],
    redirect: _91id_93VTouUh2e7uMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAPWw0wgztgMeta?.name ?? "users",
    path: indexAPWw0wgztgMeta?.path ?? "/users",
    meta: indexAPWw0wgztgMeta || {},
    alias: indexAPWw0wgztgMeta?.alias || [],
    redirect: indexAPWw0wgztgMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240916112336/pages/users/index.vue").then(m => m.default || m)
  }
]